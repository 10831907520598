import { Add } from "@mui/icons-material";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Fab } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { PerfChartsAPI } from "api/BackendApi/PerfChartsAPI";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography/index.js";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import AddNewDashboard from "./AddNewDashboard.js";
import ChartConfigsKanbanPerformance from "./ChartConfigsKanbanPerformance.js";
import AddPerfChartDialog from "./AddPerfChart.js";

const loading = () => {
  return (<ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

function RowDashboardPerformance({ graphTypes, module, onConfigChange }) {

  const [addChartDialogOpen, setAddChartDialogOpen] = useState(false)

  const scrollRef = useRef(null);

  // Handle wheel scrolling for horizontal scroll
  const handleWheel = (event) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += event.deltaY;  // scroll horizontally based on vertical wheel scroll
      event.preventDefault();  // Prevent default vertical scroll behavior
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const onCardOrderChange = async (configs) => {
    let orderConfigMap = {};
    for (let [index, config] of Object.entries(configs)) {
      orderConfigMap[config._id] = index;
    }
    await PerfChartsAPI.setChartsConfigsOrder(orderConfigMap, "API");
    enqueueSnackbar("Charts order changed!", { variant: "success" })
  }

  const getTable = () => {
    if (graphTypes == null) {
      return loading();
    }

    return (
      <Grid container direction="column">
        <Grid item>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              Dashboard Performance API
            </ArgonTypography>
          </ArgonBox>
        </Grid>

        {/* Scrollable container with scroll wheel support */}
        <div
          ref={scrollRef}
          style={{ display: "flex", overflowX: "auto", whiteSpace: "nowrap", width: "100%" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ChartConfigsKanbanPerformance
              key={module._id}
              configs={graphTypes}
              outputValueUpdate={onCardOrderChange}
              onConfigChange={onConfigChange}
            />
            <Fab
              color="white"
              style={{ marginBottom: 20, marginLeft: 10, flexShrink: 0, marginRight: 30 }}
              onClick={() => setAddChartDialogOpen(true)}
            >
              <Add color="primary" style={{ height: 25, width: 25 }} />
            </Fab>
          </div>
        </div>
      </Grid>
    );
  }

  return (

    <Card sx={{ marginBottom: "3em" }}>
      <Dialog
        open={addChartDialogOpen}
        onClose={() => setAddChartDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Add Performance Chart"}</DialogTitle>
        <DialogContent>
          <AddPerfChartDialog closeDialog={() => {
            onConfigChange();
            setAddChartDialogOpen(false);
          }} />
        </DialogContent>
      </Dialog>
      <Grid container>
        {getTable()}
      </Grid>
    </Card>
  );
}


export default RowDashboardPerformance;

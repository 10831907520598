import { useEffect, useState } from "react";

// @mui material components
import styled from "@emotion/styled";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, Switch, Tooltip, tooltipClasses } from "@mui/material";
import typography from "assets/theme/base/typography";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import ArgonButton from "components/ArgonButton";
import { useNavigate } from "react-router-dom";
import { Assignment, Delete } from "@mui/icons-material";
import { TestRunsAPI } from "api/BackendApi/TestRuns";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const UnitSwitch = ({ handleChange, isPercentage }) => {
    return (
        <FormControlLabel
            control={<Switch checked={isPercentage} onChange={(event) => handleChange(event.target.checked)} sx={{
                marginTop: 1,
            }} />}
            label={<NoMaxWidthTooltip title={"Show time difference between builds in " + (isPercentage ? "percentage" : "milliseconds")} placement="top">
                {isPercentage ? "Percentage" : "Milliseconds"}
            </NoMaxWidthTooltip>}

        />
    );
};


function PerfUIStepsTable({ data, testInstanceName, comparingBuild, selectedBuild, refreshTrigger }) {

    const [isPercentage, setIsPercentage] = useState(true);

    const [isDeleting, setIsDeleting] = useState(false);

    const [dialogDeletingRunId, setDialogDeletingRunId] = useState(null)

    const { size } = typography;

    const [columns, setColumns] = useState([{
        accessorKey: 'name',
        header: 'Name',
        grow: true
    }]);

    const [tableData, setTableData] = useState([]);

    const percentageDifference = (reference, value) => {
        if (reference === 0) {
            throw new Error("Reference value cannot be zero.");
        }
        return (((value - reference) / reference) * 100).toFixed(1);
    }

    const millisecondDifference = (reference, value) => {
        return (value - reference).toFixed(2);
    }

    const arrayAverage = (arr) => {
        if (arr.length === 0) return 0; // Handle empty array case
        return (arr.reduce((sum, num) => sum + num, 0) / arr.length);
    }

    const createCellContent = (valueComparing, valueSelected) => {
        if (valueComparing == null) return "Missing";

        let tooltip = <div>{comparingBuild + " "}<b>{valueComparing.toFixed(2)}ms</b></div>
        if (valueSelected != null) {
            tooltip = <div>{comparingBuild + " "}<b>{valueComparing.toFixed(2)}ms</b> <br /> {selectedBuild} <b>{valueSelected.toFixed(2)}ms</b> </div>
        }

        let cellContent = <></>
        if (valueSelected != null) {
            let icon = "trending_flat"
            let iconColor = "primary"

            if (valueComparing > valueSelected) {
                icon = "trending_down"
                iconColor = "error"
            }
            if (valueComparing < valueSelected) {
                icon = "trending_up"
                iconColor = "success"
            }
            cellContent = <ArgonBox
                fontSize={size.lg}
                color={iconColor}
                mb={0.3}
                mr={0.5}
                lineHeight={0}
                display="flex"
                alignItems="center"
            >
                <Icon sx={{ fontWeight: "bold", mr: 0.5 }}>{icon}</Icon>
                <ArgonTypography fontSize="12px" color={iconColor}>
                    {isPercentage ? percentageDifference(valueComparing, valueSelected) + "%" : millisecondDifference(valueComparing, valueSelected) + "ms"}
                </ArgonTypography>
            </ArgonBox>

        }

        return <ArgonTypography variant="body2">
            <NoMaxWidthTooltip title={tooltip} placement="top">
                <b>{valueComparing?.toFixed(2)}</b> ms {cellContent}
            </NoMaxWidthTooltip>
        </ArgonTypography>
    }

    const goToResults = (runs) => {
        window.open('/test-results?runs=' + runs.join(","), "_blank");
    }

    const deleteRunConfirm = async () => {
        setIsDeleting(true)
        try {
            await TestRunsAPI.deleteRun(dialogDeletingRunId)
            refreshTrigger?.()
            setDialogDeletingRunId(null)
        } finally {
            setIsDeleting(false)
        }
    }

    useEffect(() => {
        var comparingData = data.comparing
        var selectedData = data.selected

        let columns = [{
            accessorKey: 'test',
            header: 'Test',
            grow: true,
        }]

        let rows = []
        let labels = comparingData.steps

        var stepNumber = 1
        for (var label of labels) {
            columns.push({
                accessorKey: label.replace(".", "_"),
                header: <NoMaxWidthTooltip title={label} placement="top">
                    {"Step #" + stepNumber}
                </NoMaxWidthTooltip>,
                grow: true,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                }
            })
            stepNumber++
        }

        columns.push({
            accessorKey: "actions",
            header: "Actions",
            grow: true,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            muiTableFooterCellProps: {
                align: 'center',
            }
        })
        var runCountComparing = comparingData.ordered_run_ids.length

        let total = {}

        // First calculate the average of all runs for each step of selectedData
        let aggregatedData = {}
        let selectedDataSteps = selectedData.steps
        for (var runId of selectedData.ordered_run_ids) {
            for (var step of selectedDataSteps) {
                var value = selectedData.steps_table[runId].steps[step]
                if (!(step in aggregatedData)) {
                    aggregatedData[step] = []
                }
                if (value != null) {
                    aggregatedData[step].push(value)
                }
            }
        }

        for (var step of selectedDataSteps) {
            aggregatedData[step] = arrayAverage(aggregatedData[step])
        }

        for (let runIndex = 0; runIndex < runCountComparing; runIndex++) {
            let comparingRunId = comparingData.ordered_run_ids[runIndex]
            let rowData = {
                "test": <NoMaxWidthTooltip title={"Started at: " + new Date(comparingData.dates[comparingRunId]).toISOString()} placement="top">
                    <ArgonTypography variant="body2">{testInstanceName}</ArgonTypography>
                </NoMaxWidthTooltip>,
                "actions": <Grid container direction={"row"} justifyContent={"center"} spacing={2}>
                    <Grid item>
                        <ArgonButton
                            key="results"
                            color="info"
                            variant="contained"
                            size="medium"
                            iconOnly
                            onClick={() => { goToResults([comparingData.ordered_run_ids[runIndex]]) }}
                        >
                            <Assignment />
                        </ArgonButton>
                    </Grid>
                    <Grid item>
                        <ArgonButton
                            key="delete-run"
                            color="error"
                            variant="contained"
                            size="medium"
                            iconOnly
                            onClick={() => { setDialogDeletingRunId(comparingData.ordered_run_ids[runIndex]) }}
                        >
                            <Delete />
                        </ArgonButton>
                    </Grid>
                </Grid>
            }

            for (var label of labels) {
                let valueComparing = comparingData.steps_table[comparingRunId].steps[label]

                if (!(label in total)) {
                    total[label] = { comparing: [] }
                }
                if (valueComparing != null) {
                    total[label].comparing.push(valueComparing)
                }

                rowData[label.replace(".", "_")] = createCellContent(valueComparing, aggregatedData[label] ?? null)
            }

            rows.push(rowData)
        }

        let recapRow = {
            "isRecap": true,
            "test": <ArgonTypography variant="body2"><b>{"Total (Average)"}</b></ArgonTypography>,
            "actions": <ArgonButton
                key="results"
                color="info"
                variant="text"
                onClick={() => { goToResults(comparingData.ordered_run_ids) }}
            >
                <Assignment /> All Results
            </ArgonButton>
        }

        for (let [label, values] of Object.entries(total)) {
            recapRow[label] = createCellContent(arrayAverage(values.comparing), aggregatedData[label] ?? null)
        }
        rows.push(recapRow)

        setColumns(columns)
        setTableData(rows)
    }, [data, testInstanceName, isPercentage])

    const table = useMaterialReactTable({
        columns: columns,
        data: tableData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { columnVisibility: { id: false }, density: 'compact', pagination: { pageSize: 30 } },
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        renderTopToolbarCustomActions: ({ table }) => (Object.values(data.selected)[0]?.length ?? 0) == 0 ? <Box></Box> : <Box ml={2}><UnitSwitch handleChange={setIsPercentage} isPercentage={isPercentage} /></Box>,
        muiPaginationProps: {
            rowsPerPageOptions: [30, 50, 100],
        },
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiEditTextFieldProps: {
            inputProps: {
                sx: {
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "0.8rem",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                fontSize: "0.8rem",
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'center',
                },
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.original.isRecap === true ? "#f1f8fe" : "#ffffff",
                // cursor: 'default'
            },
        }),
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 10000 : undefined,
            },
        }),
    });

    return (
        <>
            <Dialog
                open={dialogDeletingRunId != null}
                onClose={() => setDialogDeletingRunId(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"md"}
            >
                <DialogTitle textAlign="center">{"Delete Run"}</DialogTitle>
                <DialogContent>
                    <DialogContent>
                        <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
                            <ArgonBox display="flex" justifyContent="center">
                                <ArgonBox>
                                    <ArgonButton color="dark" onClick={() => setDialogDeletingRunId(null)}>
                                        Back
                                    </ArgonButton>
                                    <ArgonButton color="error" sx={{ ml: 2 }} onClick={deleteRunConfirm}>
                                        {isDeleting ? <CircularProgress color="white" size={14} /> : <Delete />}
                                        &nbsp; {isDeleting ? "Deleting..." : "Yes, Delete"}
                                    </ArgonButton>
                                </ArgonBox>
                            </ArgonBox>
                        </ArgonBox>
                    </DialogContent>
                </DialogContent>
            </Dialog>
            <MaterialReactTable table={table} />
        </>
    );
}

export default PerfUIStepsTable;
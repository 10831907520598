import Board, { addColumn } from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import { Box, CircularProgress } from '@mui/material';
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";


import ControllerCardPerformanceCharts from "components/Cards/ControllerCardPerformanceCharts";
import { enqueueSnackbar } from "notistack";
import ResponseTimePctOverTime from 'pages/performance/components/ResponseTimePctOverTime';
import { PerfChartsAPI } from 'api/BackendApi/PerfChartsAPI';
import ResponseTimeVsRequest from 'pages/performance/components/ResponseTimeVsRequest';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

function ChartConfigsKanbanPerformance({ title, configs, outputValueUpdate, onConfigChange }) {

  const chartTypeToNameMap = {
    "over-time": "Over Time"
  }

  const statisticToNameMap = {
    "avg": "Average RT",
    "max": "Max RT",
    "min": "Minimum RT",
    "median": "Median RT",
    "pct90": "PCT 90 RT",
    "pct95": " PCT 95 RT",
    "pct99": " PCT 99 RT",
    "throughput": "Throughput",
    "status-codes": "Status Codes",
    "resp-time-throughput": "RT vs Throughput"
  }

  const [sampleRows, setSampleRows] = useState([
    {
      c1: "Value 1",
      c2: "Value 2",
    },
    {
      c1: "Value 1",
      c2: "Value 2",
    },
    {
      c1: "Value 1",
      c2: "Value 2",
    }
  ])

  const [sampleCols, setSampleCols] = useState([
    {
      accessorKey: 'c1',
      header: 'Col 1',
      grow: false,
      size: 118,
      muiTableBodyCellProps: {
        align: 'center'
      }
    },
    {
      accessorKey: 'c2',
      header: 'Col 2',
      grow: false,
      size: 100,
      muiTableBodyCellProps: {
        align: 'center'
      }
    }
  ])

  const table = useMaterialReactTable({
    columns: sampleCols,
    data: sampleRows,
    // initialState: { density: 'compact' },
    enableColumnResizing: false,
    layoutMode: 'grid',
    enableDensityToggle: false,
    enableEditing: false,
    enableBottomToolbar: false,
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    muiTableHeadProps: {
      sx: {
        boxShadow: "none",
        backgroundColor: "#ffffff",
      },
    },
    muiTableContainerProps: {
      sx: {
        boxShadow: "none",
        overflowX: "hidden"
      },
    },
    muiPaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#ffffff",
        boxShadow: "none",
        borderBottom: "none", // Ensure no shadow-like border
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
        borderBottom: "none", // Remove any bottom border if it looks like a shadow
      },
    },
  });

  const defaultAvailableWidths = [{ "value": 1, "label": "1" }, { "value": 2, "label": "2" }, { "value": 3, "label": "3" }, { "value": 4, "label": "4" }]

  const availableWidths = {
    "table-avg": [2, 4],
    "scatter-resp-time-throughput": [],
    "over-time-status-codes": []
  }

  const iconMap = {
    "over-time": (chart) => {
      return <ResponseTimePctOverTime
        height='250px'
        showTitle={false}
        card={false}
        compact={true}
        download={false}
        fillArea={chart["line"] == "fill"}
        selectedStatistic={"avg"}
        selectedLabel={"All"}
        statisticsSelectedBuild={{
          "All": {
            "avg": [
              16.4,
              14.68,
              12.86,
              12.38,
              12.06,
              11.88,
              12.21,
              10.22,
              9.5,
              8.82,
              10.01,
              9.58,
              9.54,
              9.56,
              9.96,
              9.6,
              9.74,
              9.63,
              9.9,
              8.92,
              9.9,
              9.47,
              9.24,
              8.96,
              9.25,
              9.55,
              9.28,
              9.41,
              8.92,
              9.5,
              9.81,
              9.53,
              8.82,
              10.85,
              9.55,
              9.38,
              9.53,
              9.73,
              9.38,
              9.17,
              9.36,
              9.66,
              9.32,
              9.66,
              9.37,
              9.27,
              9.79,
              9.16,
              9.69,
              10.11,
              9.96,
              9.49,
              9.72,
              9.14,
              9.69,
              9.64,
              9.36
            ],
            "times": [
              "00:00",
              "00:01",
              "00:02",
              "00:03",
              "00:04",
              "00:05",
              "00:06",
              "00:07",
              "00:08",
              "00:09",
              "00:10",
              "00:11",
              "00:12",
              "00:13",
              "00:14",
              "00:15",
              "00:16",
              "00:17",
              "00:18",
              "00:19",
              "00:20",
              "00:21",
              "00:22",
              "00:23",
              "00:24",
              "00:25",
              "00:26",
              "00:27",
              "00:28",
              "00:29",
              "00:30",
              "00:31",
              "00:32",
              "00:33",
              "00:34",
              "00:35",
              "00:36",
              "00:37",
              "00:38",
              "00:39",
              "00:40",
              "00:41",
              "00:42",
              "00:43",
              "00:44",
              "00:45",
              "00:46",
              "00:47",
              "00:48",
              "00:49",
              "00:50",
              "00:51",
              "00:52",
              "00:53",
              "00:54",
              "00:55",
              "00:56",
              "00:57",
              "00:58"
            ]
          },
        }}
        statisticsTargetBuild={{
          "All": {
            "avg": [
              14.68,
              12.86,
              12.38,
              12.06,
              11.88,
              12.21,
              10.22,
              9.5,
              8.82,
              10.01,
              12.58,
              9.54,
              9.56,
              9.96,
              9.6,
              9.74,
              9.63,
              9.9,
              8.92,
              9.9,
              9.47,
              9.24,
              12.96,
              9.25,
              9.55,
              9.28,
              9.41,
              8.92,
              9.5,
              9.81,
              9.53,
              8.82,
              10.85,
              9.55,
              9.38,
              9.53,
              9.73,
              9.38,
              9.17,
              9.36,
              9.66,
              9.32,
              7.66,
              9.37,
              9.27,
              9.79,
              9.16,
              9.69,
              10.11,
              9.96,
              9.49,
              9.72,
              9.14,
              4.69,
              9.64,
              9.36
            ],
            "times": [
              "00:00",
              "00:01",
              "00:02",
              "00:03",
              "00:04",
              "00:05",
              "00:06",
              "00:07",
              "00:08",
              "00:09",
              "00:10",
              "00:11",
              "00:12",
              "00:13",
              "00:14",
              "00:15",
              "00:16",
              "00:17",
              "00:18",
              "00:19",
              "00:20",
              "00:21",
              "00:22",
              "00:23",
              "00:24",
              "00:25",
              "00:26",
              "00:27",
              "00:28",
              "00:29",
              "00:30",
              "00:31",
              "00:32",
              "00:33",
              "00:34",
              "00:35",
              "00:36",
              "00:37",
              "00:38",
              "00:39",
              "00:40",
              "00:41",
              "00:42",
              "00:43",
              "00:44",
              "00:45",
              "00:46",
              "00:47",
              "00:48",
              "00:49",
              "00:50",
              "00:51",
              "00:52",
              "00:53",
              "00:54",
              "00:55",
              "00:56",
              "00:57",
              "00:58"
            ]
          },
        }}
        comparingBuild={"Comparing"}
        selectedBuild={"Selected"}
      />;
    },
    "scatter": (chart) => {
      return <ResponseTimeVsRequest
        height='250px'
        showTitle={false}
        card={false}
        compact={true}
        download={false}
        selectedLabel={"All"}
        statisticsBuild={{
          "All": {
            "resp_time_vs_throughput": {
              "nok": [
                [
                  2,
                  6.0
                ],
                [
                  3,
                  6
                ],
                [
                  4,
                  5.0
                ],
                [
                  5,
                  5
                ],
                [
                  6,
                  5.5
                ],
                [
                  7,
                  5
                ],
                [
                  7,
                  6
                ],
                [
                  7,
                  5
                ],
                [
                  7,
                  5
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  8,
                  5.0
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  6
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  9,
                  5
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ],
                [
                  10,
                  5.0
                ]
              ],
              "ok": [
                [
                  27,
                  7
                ],
                [
                  36,
                  7.0
                ],
                [
                  45,
                  7
                ],
                [
                  54,
                  7.0
                ],
                [
                  63,
                  6
                ],
                [
                  64,
                  6.0
                ],
                [
                  69,
                  6
                ],
                [
                  70,
                  6.0
                ],
                [
                  71,
                  6
                ],
                [
                  72,
                  6.0
                ],
                [
                  73,
                  6
                ],
                [
                  74,
                  6.0
                ],
                [
                  74,
                  6.0
                ],
                [
                  75,
                  6
                ],
                [
                  78,
                  6.0
                ],
                [
                  78,
                  6.0
                ],
                [
                  78,
                  6.0
                ],
                [
                  78,
                  6.0
                ],
                [
                  79,
                  6
                ],
                [
                  79,
                  6
                ],
                [
                  80,
                  6.0
                ],
                [
                  80,
                  6.0
                ],
                [
                  81,
                  6
                ],
                [
                  81,
                  6
                ],
                [
                  81,
                  6
                ],
                [
                  82,
                  6.0
                ],
                [
                  82,
                  6.0
                ],
                [
                  82,
                  6.0
                ],
                [
                  82,
                  6.0
                ],
                [
                  82,
                  6.0
                ],
                [
                  83,
                  6
                ],
                [
                  83,
                  6
                ],
                [
                  83,
                  6
                ],
                [
                  83,
                  6
                ],
                [
                  83,
                  6
                ],
                [
                  83,
                  6
                ],
                [
                  84,
                  6.0
                ],
                [
                  84,
                  6.0
                ],
                [
                  84,
                  6.0
                ],
                [
                  84,
                  6.0
                ],
                [
                  85,
                  6
                ],
                [
                  85,
                  6
                ],
                [
                  85,
                  6
                ],
                [
                  86,
                  6.0
                ],
                [
                  87,
                  6
                ],
                [
                  87,
                  6
                ],
                [
                  87,
                  6
                ],
                [
                  87,
                  6
                ],
                [
                  87,
                  6
                ],
                [
                  87,
                  6
                ],
                [
                  87,
                  6
                ],
                [
                  88,
                  6.0
                ],
                [
                  89,
                  6
                ],
                [
                  90,
                  6.0
                ],
                [
                  90,
                  6.0
                ],
                [
                  90,
                  6.0
                ],
                [
                  90,
                  6.0
                ]
              ],
              "throughputs": [
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                15,
                27,
                36,
                45,
                54,
                63,
                64,
                69,
                70,
                71,
                72,
                73,
                74,
                75,
                78,
                79,
                80,
                81,
                82,
                83,
                84,
                85,
                86,
                87,
                88,
                89,
                90
              ]
            }
          }
        }}
        build={"Build 1"}
      />;
    },
    "table": (c) => {
      return <div><Box height={"12px"} /><div style={{ overflowX: 'hidden', maxWidth: '100%', width: '100%' }}><MaterialReactTable table={table} /></div></div>
    }
  };

  const [board, setBoard] = useState(null);

  useEffect(() => {
    if (configs == null) return;
    createKanban();
  }, [configs])


  const boardToTAFData = (boardData) => {
    // This should return a list of tests, if empty return null
    let configs = [];
    for (let column of boardData.columns) {
      configs.push(column.cards[0])
    }
    return configs;
  };

  const createKanban = async () => {

    let currentBoard = { columns: [] };
    let index = 0;
    for (let config of configs.sort((a, b) => a.order > b.order ? 1 : -1)) {
      currentBoard = addColumn(currentBoard, {
        id: index,
        card_id: config._id,
        title: config.name,
        cards: [config]
      });
      index++;
    }

    setBoard(currentBoard);
  }

  const onColumnDragEnd = (column, source, destination) => {

    let tmp = board.columns[destination.toPosition]

    let newBoard = { ...board };
    newBoard.columns[destination.toPosition] = board.columns[source.fromPosition];
    newBoard.columns[source.fromPosition] = tmp;
    setBoard(newBoard);
    outputValueUpdate(boardToTAFData(newBoard));
  }

  const reload = () => {
    onConfigChange();
    createKanban();
  }

  const cardToTitle = (card) => {
    if (card.type == "table") return "Performance Table"
    return (statisticToNameMap[card.statistic] ?? "") + " " + (chartTypeToNameMap[card.type] ?? "")
  }

  const renderColumnHeader = (column) => {
    const enableDelete = configs.length > 1;
    const card = configs.find(c => c._id == column.card_id);
    if (card == null) return;
    return (
      <ControllerCardPerformanceCharts
        key={card._id}
        configId={card._id}
        deletable={enableDelete}
        icon={iconMap[card["type"]]?.(card) ?? <div>Missing &quot;{card["type"]}&quot;</div>}
        title={cardToTitle(card)}
        description={card["description"]}
        chartWidth={card["width"] ?? 1}
        availableWidths={availableWidths[card["type"] + "-" + card["statistic"]]?.map(v => { return { value: v, label: v } }) ?? defaultAvailableWidths}
        onRemove={() => {
          enqueueSnackbar("Chart removed!", { variant: "success" })
          reload();
        }}
        onWidthChange={async (value) => {
          await PerfChartsAPI.setChartWidth(card["_id"], value["value"], "API");
          enqueueSnackbar("Width changed to " + value["value"], { variant: "success" })
          reload();
        }}
      />
    );
  }

  const renderCard = (card, { removeCard, dragging }) => {
    return <></>;
  }

  if (board == null || board?.columns?.length == 0) return <Stack direction='row' justifyContent='center'><CircularProgress /></Stack>;
  return (
    <Board
      onColumnDragEnd={onColumnDragEnd}
      renderColumnHeader={renderColumnHeader}
      renderCard={renderCard}
      disableCardDrag={true}
      allowRemoveColumn
      disableColumnDrag={!window.user.canEditChart()}
    >{board}</Board>
  );
}

ChartConfigsKanbanPerformance.propTypes = {
  title: PropTypes.string,
  configs: PropTypes.array,
  outputValueUpdate: PropTypes.func
};

export default ChartConfigsKanbanPerformance;
import { useEffect, useState } from "react"

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAlert from "components/ArgonAlert";
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress, Tab, TextField } from '@mui/material';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { addTest } from "api/BackendApi/TestRepository";
import ArgonSnackbar from "components/ArgonSnackbar";
import { enqueueSnackbar } from "notistack";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function AddNewTestView({ closeDialog, folderId, enableAiScripts }) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [description, setDescription] = useState("");
  const [precondition, setPrecondition] = useState("");
  const [binaries, setBinaries] = useState("");
  const [command, setCommand] = useState("");
  const [testArguments, setArguments] = useState("");
  const [activeTab, setActiveTab] = useState("1")

  const [aiScript, setAIScript] = useState("")

  const saveNewTest = async () => {
    setSaving(true);

    try {
      if (activeTab == "1") {
        await addTest(folderId, {
          "name": name,
          "notes": notes,
          "description": description,
          "precondition": precondition,
          "binaries": binaries,
          "command": command,
          "arguments": testArguments
        })
      } else {
        await addTest(folderId, {
          "name": name,
          "testType": "ai",
          "aiScript": aiScript
        })
      }
      enqueueSnackbar("Test added successfully", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Error", { variant: "error" });
    } finally {
      setSaving(false);
      closeDialog();
    }
  };

  const getAutomatedTestTab = () => {
    return <ArgonBox component="form" role="form">
      <ArgonTypography fontSize={14}>Test Name</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Test Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && name !== "") {
              saveNewTest()
              e.preventDefault();
            } else if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ArgonTypography fontSize={14}>Test Description</ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Test Description"
              value={description}
              size="medium"
              onChange={(e) => { setDescription(e.target.value) }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && name !== "") {
                  saveNewTest()
                  e.preventDefault();
                } else if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ArgonTypography fontSize={14}>Test Precondition</ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Test Precondition"
              value={precondition}
              size="medium"
              onChange={(e) => { setPrecondition(e.target.value) }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && name !== "") {
                  saveNewTest()
                  e.preventDefault();
                } else if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </ArgonBox>
        </Grid>
      </Grid>
      <ArgonTypography fontSize={14}>Notes</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Notes"
          value={notes}
          size="medium"
          onChange={(e) => { setNotes(e.target.value) }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && name !== "") {
              saveNewTest()
              e.preventDefault();
            } else if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </ArgonBox>
      <ArgonTypography fontSize={14}>Binaries</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Binaries"
          value={binaries}
          size="medium"
          onChange={(e) => { setBinaries(e.target.value) }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && name !== "") {
              saveNewTest()
              e.preventDefault();
            } else if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ArgonTypography fontSize={14}>Command</ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Command"
              value={command}
              size="medium"
              onChange={(e) => { setCommand(e.target.value) }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && name !== "") {
                  saveNewTest()
                  e.preventDefault();
                } else if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ArgonTypography fontSize={14}>Arguments</ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Arguments"
              value={testArguments}
              size="medium"
              onChange={(e) => { setArguments(e.target.value) }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && name !== "") {
                  saveNewTest()
                  e.preventDefault();
                } else if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </ArgonBox>
        </Grid>
      </Grid>
    </ArgonBox>
  }

  const getAiScriptTab = () => {
    return <ArgonBox component="form" role="form">
      <ArgonTypography fontSize={14}>Test Name</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Test Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && name !== "") {
              saveNewTest()
              e.preventDefault();
            } else if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </ArgonBox>
      <Grid container>
        <Grid item xs={12} md={12}>
          <ArgonTypography fontSize={14}>Test</ArgonTypography>
          <ArgonBox mb={2} height={200} width={"100%"}>
            <ArgonInput
              style={{
                "width": "100%",
                "boxSizing": "border-box",
                "borderColor": "lightgrey",
                "padding": "10px",
                "resize": "none"
              }}
              rows={10}
              placeholder="Test Script"
              value={aiScript}
              multiline={true}
              fullWidth
              onChange={(e) => { setAIScript(e.target.value) }}
            />
          </ArgonBox>
        </Grid>
      </Grid>
    </ArgonBox>;
  }

  return (
    <Box>
      {enableAiScripts && <TabContext value={activeTab}>
        <TabList sx={{ height: '2em' }} onChange={(e, v) => setActiveTab(v)} aria-label="lab API tabs example">
          <Tab label="Script" value="1" />
          <Tab label="AI" value="2" />
        </TabList>
        <TabPanel value="1" sx={{ width: '100%', typography: 'body1' }}>
          {getAutomatedTestTab()}
        </TabPanel>
        <TabPanel value="2" sx={{ width: '100%', typography: 'body1' }}>
          {getAiScriptTab()}
        </TabPanel>
      </TabContext>}
      {!enableAiScripts && getAutomatedTestTab()}
      <ArgonBox display="flex" justifyContent="center" mt={2}>
        <ArgonBox mr={1.5}>
          {name === "" ? (
            <ArgonButton color="primary" disabled>
              <SaveIcon />&nbsp; Save
            </ArgonButton>
          ) : (
            <ArgonButton color="primary" onClick={saveNewTest}>
              {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
              &nbsp; {saving ? "Saving..." : "Save"}
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>
    </Box>
  );
}


AddNewTestView.propTypes = {
  closeDialog: PropTypes.func,
  folderId: PropTypes.string
};

export default AddNewTestView;

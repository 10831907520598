import { Box, Card } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import colors from "assets/theme/base/colors";

const { white, monitoring_primary, monitoring_secondary, dark } = colors;

const statisticToTitle = (statistic) => {
    return {
        "avg": "Response Time (Average) Over Time",
        "max": "Response Time (Max) Over Time",
        "min": "Response Time (Min) Over Time",
        "median": "Response Time (Median) Over Time",
        "pct90": "Response Time (90° Percentile) Over Time",
        "pct95": "Response Time (95° Percentile) Over Time",
        "pct99": "Response Time (99° Percentile) Over Time",
        "throughput": "Throughput Over Time",

    }[statistic]
}

const statisticToYAxisLabel = (statistic) => {
    return {
        "avg": "Response Time (ms)",
        "max": "Response Time (ms)",
        "min": "Response Time (ms)",
        "median": "Response Time (ms)",
        "pct90": "Response Time (ms)",
        "pct95": "Response Time (ms)",
        "pct99": "Response Time (ms)",
        "throughput": "Number of requests per second",

    }[statistic]
}


function ResponseTimePctOverTime({ selectedStatistic, selectedLabel, statisticsSelectedBuild, statisticsTargetBuild, comparingBuild, selectedBuild, fillArea, height = '500px', showTitle = true, card = true, compact = false, download = true }) {

    const [_selectedStatistic, setSelectedStatistic] = useState(null)

    const [_selectedLabel, setSelectedLabel] = useState(null)

    const [_statisticsSelectedBuild, setStatisticsSelectedBuild] = useState(null)

    const [_statisticsTargetBuild, setStatisticsTargetBuild] = useState(null)

    const [option, setOption] = useState(null)

    const [_selectedBuild, setSelectedBuild] = useState(null)

    const [_comparingBuild, setComparingBuild] = useState(null)

    /*useEffect(() => {
        if (_avilableLabels == null) return;
        setLabelsToggleMap(_avilableLabels.reduce(function (map, obj) {
            map[obj] = false;
            return map;
        }, {}))
    }, [_avilableLabels])*/

    useEffect(() => {
        setComparingBuild(comparingBuild)
    }, [comparingBuild])

    useEffect(() => {
        setSelectedBuild(selectedBuild)
    }, [selectedBuild])

    useEffect(() => {
        // setSelectedStatistic(selectedStatistic?.lower()) // TODO lower?
        // TODO real stastistc
        setSelectedStatistic(selectedStatistic)
    }, [selectedStatistic])

    useEffect(() => {
        setSelectedLabel(selectedLabel)
        console.log("Selected label: " + selectedLabel)
    }, [selectedLabel])

    useEffect(() => {
        setStatisticsSelectedBuild(statisticsSelectedBuild)
        setStatisticsTargetBuild(statisticsTargetBuild)
    }, [statisticsSelectedBuild,
        statisticsTargetBuild])

    useEffect(() => {
        if ([_selectedStatistic,
            _selectedLabel,
            _statisticsSelectedBuild,
            _statisticsTargetBuild,
            _selectedBuild,
            _comparingBuild].includes(null)) {
            console.log("something is null skipping");
            return;
        }

        setOption({
            title: showTitle == true ? {
                text: statisticToTitle(_selectedStatistic),
                left: 'center',
                top: '10px'
            } : {},
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                top: compact ? 0: '35px',
                data: [_comparingBuild, _selectedBuild]
            },
            toolbox: {
                feature: download == true ? {
                    saveAsImage: {}
                } : {}
            },
            grid: {
                top: compact ? '25px' : '65px',
                left: compact ? '25px' : '60px',
                right: compact ? '25px' : '40px',
                bottom: compact ? '20px' : '60px',
                containLabel: false
            },
            xAxis: [
                {
                    type: 'category',
                    name: 'Elapsed Time (granularity: 1 sec)',
                    nameLocation: 'middle',
                    nameGap: 35,
                    data: _statisticsTargetBuild[_selectedLabel]["times"]
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: statisticToYAxisLabel(_selectedStatistic),
                    nameLocation: 'middle',
                    nameGap: 35
                }
            ],
            series: [_comparingBuild, _selectedBuild].map(label => {
                let color = { [_selectedBuild]: monitoring_secondary.main, [_comparingBuild]: monitoring_primary.main }[label]
                var config = {
                    name: label,
                    type: 'line',
                    // stack: 'Total',
                    color: color,
                    data: { [_selectedBuild]: _statisticsSelectedBuild, [_comparingBuild]: _statisticsTargetBuild }[label]?.[_selectedLabel]?.[_selectedStatistic] ?? []
                }
                if (fillArea == true) {
                    config["areaStyle"] = {
                        color: color,
                        opacity: 0.5
                    }
                }
                return config;
            })
        });
    }, [_selectedStatistic,
        _selectedLabel,
        _statisticsTargetBuild,
        _statisticsSelectedBuild,
        _selectedBuild,
        _comparingBuild
    ]);

    return card == true ? <Card >
        {option && <ReactEcharts option={option} style={{ height: height }} />}


    </Card> : <>{option && <ReactEcharts option={option} style={{ height: height }} />}</>

}

export default ResponseTimePctOverTime;
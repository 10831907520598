import apiClient from "api/BackendApi";


const PerfChartsAPI = {
  getConfigs: (platform) => apiClient.get("/be/perf/config/list?platform=" + platform),
  addConfig: (body, platform) => apiClient.post("/be/perf/config/add?platform=" + platform, body),
  remove: (id, platform) => apiClient.delete("/be/perf/config/remove?id=" + id + "&platform=" + platform),
  setChartWidth: (configId, width) => apiClient.post("/be/perf/config/chart_width", { "config_id": configId, "width": width }),
  getRunsTable: (configurationFilters, platform) => apiClient.post(
    "/be/perf/tests_runs_table?platform=" + platform,
    { "filters": configurationFilters }
  ),
  getBuildsTable: (configurationFilters, platform, startDate = null, endDate = null, forceBaseline = false) => apiClient.post(
    "/be/perf/builds_table?platform=" + platform,
    { "filters": configurationFilters, "startDate": startDate, "endDate": endDate, "forceBaseline": forceBaseline }
  ),
  getBuilds: (platform) => apiClient.get("/be/perf/builds?platform=" + platform),
  loadComparisonCharts: (data, platform, forceBaseline = false) => apiClient.post("/be/perf/build_comparison?platform=" + platform, { ...data, "forceBaseline": forceBaseline }),
  setChartsConfigsOrder: (orders, platform) => apiClient.post("/be/perf/config/order?platform=" + platform, orders),
}

export {
  PerfChartsAPI
};

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardHeader, CircularProgress } from "@mui/material";
import CardContent from "@mui/material/CardContent";

// components
import ArgonSelect from "../../../components/ArgonSelect";

function PerformanceSelectFilters({ dashboardId, configs, selectedValues, onChange }) {

  const handleFilterChange = (name, event) => {
    onChange?.(name, event?.value);
  };

  const filterToCard = (filterName) => {
    var filterValue = (selectedValues ?? {})[filterName]
    let value = filterValue != null ? { value: filterValue, label: filterValue } : null

    return <Card sx={{ overflow: "visible" }}>
      <CardHeader title={filterName}>
      </CardHeader>
      <CardContent>
        <ArgonSelect
          multi
          size={"large"}
          sx={{ overflow: "hidden" }}
          placeholder={filterName}
          isClearable={true}
          value={value}
          onChange={(event) => handleFilterChange(filterName, event)}
          options={configs[filterName].filter(f => (f.enabled && f.filters.filter(f => f.enabled && f.module._id === dashboardId).length > 0) === true).map((f) => {
            return { value: f.name, label: f.name }
          })}
        />
      </CardContent>
    </Card>;
  };

  if (configs == null) {
    return <CircularProgress />
  }

  return (
    <Grid container direction="column">
      <Grid container spacing={3}>
        {
          Object.entries(configs)
            .filter(([key, value]) => key !== "Capability")
            .filter(([key, value]) => value.find(v => (v.filters.filter(f => f.enabled && f.module._id === dashboardId).length > 0 && v.enabled) === true) != null)
            .map(([key, value]) => {
              return <Grid key={key} item xs={12} md={6} lg={3}>
                {filterToCard(key)}
              </Grid>
            })
        }
      </Grid>
    </Grid>

  );
}

export default PerformanceSelectFilters;

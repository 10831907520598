import { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Box, CircularProgress, Grid, Stack, Tooltip, tooltipClasses } from "@mui/material";
import { PerfChartsAPI } from "api/BackendApi/PerfChartsAPI";
import ArgonSelect from "components/ArgonSelect";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const loading = <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '100px' }}>
    <CircularProgress size={64} />
</Stack>;

function PerfBuildsTable({ filters, onSelectedRow, capability, refreshCounterExt }) {

    const [isLoading, setIsLoading] = useState(true)

    const [selectedStat, setSelectedStat] = useState(null);
    const [availableStatsStrings, setAvailableStatsStrings] = useState(null);
    const [availableStatsLabels, setAvailableStatsLabels] = useState(null);

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const [tableData, setTableData] = useState(null);

    const [refreshCounter, setRefreshCounter] = useState(0)

    const mapStatValueToLabel = (value) => {
        return {
            "average": "Average",
            "min": "Min",
            "max": "Max",
            "sum": "Sum",
            "errorCount": "Error Count",
            "errorPct": "Error%",
            "maxResTime": "Max",
            "medianResTim": "Median",
            "meanResTime": "Mean",
            "pct1ResTime": "PCT 90",
            "pct2ResTime": "PCT 95",
            "pct3ResTime": "PCT 99",
            "throughput": "Throughput",
            "sampleCount": "Sample Count",
            "sentKBytesPerSec": "Sent KB/s",
            "receivedKBytesPerSec": "Sent KB/s",


        }[value] ?? value;
    }

    useEffect(() => {
        if (tableData == null) {
            setIsLoading(false);
            return;
        }

        let builds_data = tableData.data
        var firstBuildRuns = Object.values(Object.values(builds_data)[0] ?? {})[0]
        var firstRun = firstBuildRuns?.[0]
        if (firstRun == null) {
            setIsLoading(false)
            setTableData(null)
            return;
        }
        var statsStrings = Object.keys(firstRun.additionalOutput.performance_api ?? firstRun.additionalOutput.performance_ui.statistics)
        var statsLabels = statsStrings.map((s) => { return { label: mapStatValueToLabel(s), value: s } })
        setAvailableStatsStrings(statsStrings)
        setAvailableStatsLabels(statsLabels)
    }, [tableData])

    useEffect(() => {
        if (availableStatsLabels == null) {
            setIsLoading(false);
            return;
        }

        var selectedStatString = selectedStat?.value;

        if (!availableStatsStrings.includes(selectedStatString)) {
            if (availableStatsStrings.includes("meanResTime")) {
                setSelectedStat({ label: "Mean", value: "meanResTime" })
            } else if (availableStatsStrings.includes("average")) {
                setSelectedStat({ label: "Average", value: "average" })
            } else {
                setSelectedStat(availableStatsLabels[0])
            }
        }

        setRefreshCounter(c => c + 1)
    }, [availableStatsStrings])

    useEffect(() => {
        if (selectedStat == null) {
            setIsLoading(false)
            return;
        }

        let builds_data = tableData.data
        let tests = tableData.tests_map
        let cycles = tableData.cycles_map
        let instances = tableData.instances

        let newColumns = [
            {
                accessorKey: 'id',
                header: 'ID',
                enableResizing: false,
                enableHiding: false,
                enableEditing: false,
            },
            {
                accessorKey: 'testName',
                header: 'Test',
                grow: true,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                }
            }
        ];

        let data = []

        let allBuilds = new Set()

        for (var [instance, build_obj] of Object.entries(builds_data)) {
            if (!(instance in instances)) continue;
            if (!(instance in tests)) continue;
            let row = {
                "id": instance,
                "instance": instances[instance],
                "cycle": cycles[instances[instance].cycle.id],
                "testName": tests[instance].name,
                "data": build_obj
            }
            for (var build of Object.keys(build_obj)) {
                allBuilds.add(build)
                var runs = build_obj[build]
                for (var stat of availableStatsStrings) {
                    var total = 0;
                    for (var run of runs) {
                        total += (run.additionalOutput.performance_api ?? run.additionalOutput.performance_ui.statistics)[stat]
                    }
                    row[("build-" + build + "-" + stat).replace(".", "_")] = (total / runs.length).toFixed(2)
                }
            }
            data.push(row)
        }

        for (let build of allBuilds) {
            newColumns.push({
                accessorKey: ('build-' + build + "-" + selectedStat.value).replace(".", "_"),
                header: 'Build ' + build,
                grow: true,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                }
            })
        }

        setColumns(newColumns);
        setData(data)
        setIsLoading(false)
    }, [refreshCounter])

    const refreshRuns = async (refresh = false) => {
        var result = await PerfChartsAPI.getBuildsTable(filters ?? {}, capability);
        setTableData(result.data);
        if (!refresh) {
            setData([])
        }
    };

    useEffect(() => {
        setIsLoading(true)
        refreshRuns();

    }, [filters, capability]);

    useEffect(() => {
        if (refreshCounterExt == 0) return;
        refreshRuns(true);
    }, [refreshCounterExt]);

    const table = useMaterialReactTable({
        columns: columns,
        data: data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: { columnVisibility: { id: false }, density: 'compact', pagination: { pageSize: 30 } },
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        renderEmptyRowsFallback: ({ table }) => (
            <Grid container direction="row" justifyContent="center" alignItems="center"><Grid item justify="center">No builds found for {capability}</Grid></Grid>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', overflow: "visible" }}>
                <ArgonSelect
                    size={"small"}
                    sx={{ overflow: "visible" }}
                    placeholder={"Statistic"}
                    menuPortalTarget={document.body}
                    onChange={(v) => {
                        setSelectedStat(v);
                        setRefreshCounter(c => c + 1)
                    }}
                    options={availableStatsLabels}
                    value={selectedStat}
                />
            </Box>
        ),
        muiPaginationProps: {
            rowsPerPageOptions: [30, 50, 100],
        },
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiEditTextFieldProps: {
            inputProps: {
                sx: {
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "0.8rem",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                fontSize: "0.8rem",
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'center',
                },
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                onSelectedRow(row.original);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 10000 : undefined,
            },
        }),
    });

    return (
        isLoading ? loading : <MaterialReactTable table={table} />
    );
}

export default PerfBuildsTable;
import { useState } from "react";


import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress, Grid } from '@mui/material';
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonSelect from "components/ArgonSelect";
import ArgonTypography from "components/ArgonTypography";
import { enqueueSnackbar } from "notistack";
import { PerfChartsAPI } from "api/BackendApi/PerfChartsAPI";

function AddPerfChartDialog({ closeDialog }) {

  const availableTypesLabels = [
    { label: "Over Time", value: "over-time" },
    { label: "Scatter", value: "scatter" },
    { label: "Table", value: "table" },
  ]

  const availableStatisticLabels = [
    { label: "Average", value: "avg" },
    { label: "Maxium", value: "max" },
    { label: "Minumum", value: "min" },
    { label: "Median", value: "median" },
    { label: "90 PCT", value: "pct90" },
    { label: "95 PCT", value: "pct95" },
    { label: "99 PCT", value: "pct99" },
    { label: "Throughput", value: "throughput" },
    { label: "Status Code per Second", value: "status-codes" }
  ]

  const availableScatterStatisticLabels = [
    { label: "Response time vs Throughput", value: "resp-time-throughput" },
  ]

  const availableLineTypesLabels = [
    { label: "Filled", value: "fill" },
    { label: "Line", value: "line" }
  ]

  const [canSave, setCanSave] = useState(true)

  const [saving, setSaving] = useState(false);

  const [chartType, setChartType] = useState(availableTypesLabels[0]);
  const [chartStatistic, setChartStatistic] = useState(availableStatisticLabels[0]);
  const [chartLineType, setChartLineType] = useState(availableLineTypesLabels[0]);

  const addPerfChart = async () => {
    setSaving(true);
    enqueueSnackbar("New dashboard created!", { variant: "success" })
    let chartStat = chartStatistic.value
    if (chartType.value == "scatter") {
      chartStat = availableScatterStatisticLabels[0].value
    }
    let body = { type: chartType.value, stat: chartStat }
    if (chartType.value == "over-time") {
      body.lineType = chartLineType.value;
    }
    await PerfChartsAPI.addConfig(body, "API");
    setSaving(false);
    closeDialog();
  };

  const getChartSubtypeFilter = () => {
    if (chartType.value == "over-time") {
      return [
        <Grid item direction="column" key="1">
          <Grid item>
            <ArgonTypography fontSize={14}>Statistic</ArgonTypography>
          </Grid>
          <Grid item>
            <ArgonSelect
              size={"large"}
              sx={{ overflow: "hidden" }}
              placeholder={"Statistic"}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={setChartStatistic}
              defaultValue={chartStatistic}
              options={availableStatisticLabels}
            />
          </Grid>
        </Grid>,
        <Grid item direction="column" key="2">
          <Grid item>
            <ArgonTypography fontSize={14}>Line Type</ArgonTypography>
          </Grid>
          <Grid item>
            <ArgonSelect
              size={"large"}
              sx={{ overflow: "hidden" }}
              placeholder={"Statistic"}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={setChartLineType}
              defaultValue={chartLineType}
              options={availableLineTypesLabels}
            />
          </Grid>
        </Grid>
      ]
    }

    if (chartType.value == "scatter") {
      return [
        <Grid item direction="column" key="1">
          <Grid item>
            <ArgonTypography fontSize={14}>Statistic</ArgonTypography>
          </Grid>
          <Grid item>
            <ArgonSelect
              size={"large"}
              sx={{ overflow: "hidden" }}
              placeholder={"Statistic"}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={setChartStatistic}
              defaultValue={availableScatterStatisticLabels[0]}
              options={availableScatterStatisticLabels}
            />
          </Grid>
        </Grid>
      ]
    }

    if (chartType.value == "table") {
      return []
    }

  }

  return (
    <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
      <Grid container direction="row" justifyContent="space-around">
        <Grid item direction="column">
          <Grid item>
            <ArgonTypography fontSize={14}>Chart Type</ArgonTypography>
          </Grid>
          <Grid item>
            <ArgonSelect
              size={"large"}
              sx={{ overflow: "hidden" }}
              placeholder={"Chart Type"}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={setChartType}
              defaultValue={chartType}
              options={availableTypesLabels}
            />
          </Grid>
        </Grid>
        {getChartSubtypeFilter()}

      </Grid>

      <ArgonBox display="flex" justifyContent="center" mt={3}>
        <ArgonBox mr={1.5}>
          <ArgonButton color="info" onClick={addPerfChart} disabled={!(canSave && !saving)}>
            {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
            &nbsp; {saving ? "Adding..." : "Add"}
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

export default AddPerfChartDialog;

import { Box, Card } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import colors from "assets/theme/base/colors";

const statusToColor = (statistic) => {
    return {
        "200": "#77DD77",
        "500": "#FF6961",
        "404": "#AEC6CF"
    }[statistic] ?? '#f0f'
}


function ResponseCodePerSecond({ availableLabels, selectedLabel, data, build, color }) {

    const [_selectedLabel, setSelectedLabel] = useState(null)

    const [_data, setData] = useState(null)

    const [_build, setBuild] = useState(null)

    const [option, setOption] = useState(null)

    useEffect(() => {
        setSelectedLabel(selectedLabel)
    }, [selectedLabel])

    useEffect(() => {
        setData(data)
    }, [data])

    useEffect(() => {
        setBuild(build)
    }, [build])

    useEffect(() => {
        console.log("Building!")
        if ([_selectedLabel,
            _data,
            _build].includes(null)) return;

        setOption({
            title: {
                text: 'Response Code per Second ({a|' + build + '})',
                left: 'center',
                top: '10px',
                textStyle: {
                    rich: {
                        a: {
                            fontStyle: 'normal',
                            fontWeight: 'bolder',
                            fontFamily: 'sans-serif',
                            fontSize: 18,
                            color: color
                        }
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                top: '35px',
                data: Object.keys(_data[_selectedLabel]["codes"])
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            grid: {
                top: '65px',
                left: '60px',
                right: '40px',
                bottom: '60px',
                containLabel: false
            },
            xAxis: [
                {
                    type: 'category',
                    name: 'Elapsed Time (granularity: 1 sec)',
                    nameLocation: 'middle',
                    nameGap: 35,
                    data: _data[_selectedLabel]["times"]
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: "Requests number",
                    nameLocation: 'middle',
                    nameGap: 35
                }
            ],
            series: Object.keys(_data[_selectedLabel]["codes"]).map(code => {
                // let color = { [_selectedBuild]: info.main, [_comparingBuild]: primary.main }[label]
                console.log(_data[_selectedLabel]["codes"][code])
                var config = {
                    name: code,
                    type: 'line',
                    // stack: 'Total',
                    color: statusToColor(code),
                    data: _data[_selectedLabel]["codes"][code]
                }
                /*if (fillArea == true) {
                    config[areaStyle] = {
                        color: color,
                        opacity: 0.5
                    }
                }*/
                return config;
            })
        });
    }, [_selectedLabel,
        _data,
        _build
    ]);

    return <Card>
        {option && <ReactEcharts option={option} style={{ height: '500px' }} />}
    </Card>;
}

export default ResponseCodePerSecond;
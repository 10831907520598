import SignIn from "pages/sign-in/SignIn";
import TestRepository from "pages/test-repository/TestRepository";
import TestExecution from "pages/test-execution/TestExecution";
import ExecutionProgress from "pages/execution-progress/ExecutionProgress";
import UserProfile from "pages/user-profile/UserProfile";
import UserManagement from "pages/users-management/UserManagement";
import SitemapWebsite from "./pages/sitemap-website/SitemapWebsite";
import GenerativeTest from "./pages/generative-test/GenerativeTest";
import TestConfiguration from "./pages/test-configuration/TestConfiguration";
import DashboardConfiguration from "./pages/dashboard-configuration/DashboardConfiguration";
import LocatorMonitoring from "./pages/locator-monitoring/LocatorMonitoring";
import ChatbotPage from "./pages/chatbot/ChatbotPage";
import RoleManagement from "./pages/roles-management/RoleManagement";
import ModuleManagement from "./pages/module-management/ModuleManagement";
import Error500 from "./pages/error-page/500/Error500";

// Argon Dashboard 2 PRO MUI components
import LogoutIcon from '@mui/icons-material/Logout';
import ArchiveIcon from '@mui/icons-material/Archive';
import PersonIcon from '@mui/icons-material/Person';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import WebIcon from '@mui/icons-material/Web';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import { Addchart, AutoFixHigh, Mail, Schedule, SmartToy, Speed, Tune, ViewModule } from "@mui/icons-material";
import PerformancePage from "./pages/performance/PerformancePage";
import TestScheduleMngmt from "./pages/test-schedule-mngmt/TestScheduleMngmt";
import SettingsIcon from "@mui/icons-material/Settings";
import TafSettings from "./pages/taf-settings/TafSettings";
import MailingListMngmt from "pages/mailing-list-mngmt/MailingListMngmt";
import MonitoringPage from "pages/monitoring/MonitoringPage";
// Icons from here: https://mui.com/material-ui/material-icons/

const routes = (navbarRefresher) => [
  {
    type: "link",
    name: "Charts Configuration",
    module: "Chart Configuration",
    key: "charts-configuration",
    icon: <Addchart />,
    route: "charts-configuration",
    noCollapse: true,
    component: <DashboardConfiguration navbarRefresher={navbarRefresher} />,
  },
  {
    type: "link",
    name: "Filter Configuration",
    module: "Filter Configuration",
    key: "test-configuration",
    icon: <Tune />,
    route: "test-configuration",
    noCollapse: true,
    component: <TestConfiguration />,
  },
  {
    type: "link",
    name: "Performance",
    module: "Performance",
    key: "performance",
    icon: <Speed />,
    route: "performance",
    noCollapse: true,
    component: <PerformancePage />,
  },
  {
    type: "link",
    name: "Monitoring",
    module: "Monitoring",
    key: "monitoring",
    icon: <Speed />,
    route: "monitoring",
    noCollapse: true,
    component: <MonitoringPage />,
  },
  {
    type: "link",
    name: "Locator Monitoring",
    module: "Locator Monitoring",
    key: "locator-monitoring",
    icon: <TroubleshootIcon />,
    route: "locator-monitoring",
    noCollapse: true,
    component: <LocatorMonitoring />,
  },
  {
    type: "link",
    name: "Execution Progress",
    module: "Execution Progress",
    key: "execution-progress",
    icon: <LinearScaleIcon />,
    route: "execution-progress",
    noCollapse: true,
    component: <ExecutionProgress />,
  },

  {
    type: "link",
    name: "Test Repository",
    module: "Test Repository",
    key: "test-repository",
    icon: <ArchiveIcon />,
    route: "test-repository",
    noCollapse: true,
    component: <TestRepository />,
  },
  {
    type: "link",
    name: "Test Schedule",
    module: "Test Schedule",
    key: "test-schedule-mngmt",
    icon: <Schedule />,
    route: "test-schedule-mngmt",
    noCollapse: true,
    component: <TestScheduleMngmt />,
  },
  {
    type: "link",
    name: "Mailing Lists",
    module: "Mailing Lists",
    key: "mailing-list-mngmt",
    icon: <Mail />,
    route: "mailing-list-mngmt",
    noCollapse: true,
    component: <MailingListMngmt />,
  },
  {
    type: "link",
    name: "Test Execution",
    module: "Test Execution",
    key: "test-execution",
    icon: <VideoLibraryIcon />,
    route: "test-execution",
    noCollapse: true,
    component: <TestExecution />,
  },
  {
    type: "link",
    name: "Sitemap Website",
    module: "Sitemap Website",
    key: "sitemap-website",
    icon: <WebIcon />,
    route: "sitemap-website",
    noCollapse: true,
    component: <SitemapWebsite />,
  },
  {
    type: "link",
    name: "Generative Test",
    module: "Generative Test",
    key: "generative-test",
    icon: <AutoFixHigh />,
    route: "generative-test",
    noCollapse: true,
    component: <GenerativeTest />,
  },
  {
    type: "link",
    name: "User Profile",
    module: "User Profile",
    key: "user-profile",
    icon: <PersonIcon />,
    route: "user-profile",
    noCollapse: true,
    component: <UserProfile />,
  },
  {
    type: "link",
    name: "User Management",
    module: "User Management",
    key: "user-mgmt",
    icon: <GroupIcon />,
    route: "user-mgmt",
    noCollapse: true,
    component: <UserManagement />,
  },
  {
    type: "link",
    name: "Role Management",
    module: "Role Management",
    key: "role-mgmt",
    icon: <AdminPanelSettingsIcon />,
    route: "role-mgmt",
    noCollapse: true,
    component: <RoleManagement navbarRefresher={navbarRefresher} />,
  },
  {
    type: "link",
    name: "Module Management",
    module: "Module Management",
    key: "module-mgmt",
    icon: <ViewModule />,
    route: "module-mgmt",
    noCollapse: true,
    component: <ModuleManagement navbarRefresher={navbarRefresher} />,
  },
  {
    type: "link",
    name: "TAF Settings",
    module: "TAF Settings",
    key: "taf-settings",
    icon: <SettingsIcon />,
    route: "taf-settings",
    noCollapse: true,
    component: <TafSettings />,
  },
  {
    type: "link",
    name: "Taffy",
    module: "Taffy",
    key: "chatbot-taffy",
    icon: <SmartToy />,
    route: "chatbot-taffy",
    noCollapse: true,
    component: <ChatbotPage />,
  },
  {
    type: "link",
    name: "Sign Out",
    key: "sign-out",
    icon: <LogoutIcon />,
    route: "sign-in",
    noCollapse: true,
    onClick: () => {
      // TODO make a cool logout function
      localStorage.removeItem("jwt-token");
      sessionStorage.removeItem("jwt-token");
      window.user = null;
      window.user_permissions = null;
      window.permissions = null;
      location.reload();
    },
    // component: <SignIn navbarRefresher={navbarRefresher}/>,
  },
];

export default routes;

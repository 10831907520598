import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import { CircularProgress } from '@mui/material';
import ArgonBox from "components/ArgonBox";

import AddIcon from '@mui/icons-material/Add';
import Card from "@mui/material/Card";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArgonButton from "components/ArgonButton";
import DataTable from "components/DataTable";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import AddNewConfigurationTypeView from "pages/test-configuration/components/AddNewConfigurationTypeView";
import AddNewConfigurationView from "pages/test-configuration/components/AddNewConfigurationView";
import { TestConfig } from "api/BackendApi/TestConfiguration";
import { Module } from "api/BackendApi/Module";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import {
  Apps,
  Description,
  Devices,
  ImportantDevices,
  Layers,
  Public,
  Settings,
  ShoppingCart,
  Star,
  StarBorder,
  Tune
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import EditConfigurationTypeView from "./components/EditConfigurationTypeView";

const loading = () => {
  return (
    <ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={64} />
    </ArgonBox>
  );
};

const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
  width: pxToRem(40),
  minWidth: pxToRem(40),
  height: pxToRem(40),
  minHeight: pxToRem(40),
  m: 1,
});

const buttonStyleRight = ({ functions: { pxToRem } }) => ({
  width: pxToRem(40),
  minWidth: pxToRem(40),
  height: pxToRem(40),
  minHeight: pxToRem(40),
  m: 1,
});


function TestConfiguration() {
  const [addTypeDialogOpen, setAddTypeDialogOpen] = useState(false);
  const [editTypeDialogOpen, setEditTypeDialogOpen] = useState(false);
  const [addConfigDialogOpen, setAddConfigDialogOpen] = useState(false);
  const [selectedTypeTab, setSelectedTypeTab] = useState(0);
  const [selectedType, setSelectedType] = useState("Build");
  const [types, setTypes] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [modulesTAF, setModulesTAF] = useState(null);

  const [defaultColumns, setDefaultColumns] =
    useState([{ Header: "Name", accessor: "name" }, { Header: "Notes", accessor: "notes" }, { Header: "Status", accessor: "status" }]);

  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  useEffect(() => {
    loadTypes();
    loadConfigs();
    loadModules();
  }, []);

  const loadTypes = () => {
    return TestConfig.getTypes()
      .then((d) => {
        let i = 0;
        if (d.data.types.length > 0) {
          setTypes(d.data.types[0]);
        }
      })
      .catch((e) => console.log(e));
  };

  const loadConfigs = () => {
    return TestConfig.getConfigs()
      .then((d) => setConfigs(d.data.configurations))
      .catch((e) => console.log(e));
  };

  const loadModules = () => {
    return Module.getModules()
      .then((d) => {
        setModulesTAF(d.data.modules)
      })
      .catch((e) => console.log(e));
  };

  const handleAddTypeDialogOpen = () => {
    setAddTypeDialogOpen(true);
  };

  const handleEditTypeDialogOpen = () => {
    setEditTypeDialogOpen(true);
  };

  const handleAddConfigDialogOpen = () => {
    setAddConfigDialogOpen(true);
  };

  const handleClose = () => {
    setAddTypeDialogOpen(false);
    setAddConfigDialogOpen(false);
    setEditTypeDialogOpen(false);
    loadTypes();
    loadConfigs();
  };

  const handleChange = (event, newType) => {
    setSelectedTypeTab(newType);
    setSelectedType(Object.values(types)[newType].name);
  };

  const handleDisableValue = (value) => {
    return TestConfig.editConfig(value, selectedType, false)
      .then((d) => {
        enqueueSnackbar(value + " disabled!", { variant: "info" })
        loadConfigs();
      })
      .catch((e) => console.log(e));
  };

  const handleEnableValue = (value) => {
    return TestConfig.editConfig(value, selectedType, true)
      .then((d) => {
        enqueueSnackbar(value + " enabled!", { variant: "info" })
        loadConfigs();
      })
      .catch((e) => console.log(e));
  };

  const handleChangeValueModule = async (event, moduleName, module, value) => {
    try {
      await TestConfig.editConfigModule(value, selectedType, module, event.target.checked);
      loadConfigs();
      if (!event.target.checked) {
        enqueueSnackbar(moduleName + " enabled for config " + value + "!", { variant: "info" })
      } else {
        enqueueSnackbar(moduleName + " enabled for config " + value + "!", { variant: "info" })
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toggleFavoriteForCurrentType = async (wasFavorite) => {
    await TestConfig.toggleTypeFavorite(selectedType);
    await loadTypes();
    enqueueSnackbar(selectedType + (wasFavorite ? " removed from favorites" : " added to favorites"), { variant: "info" })
  }

  const getTable = () => {
    if (types == null || configs == null || modulesTAF == null) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={12}>
            {loading()}
          </Grid>
        </Grid>
      );
    }

    let currentConfigs = configs.filter(b => b.type === selectedType);
    var isFavorite = types[selectedType].favorite ?? false

    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11} lg={11}>
          <Card>
            <Grid container direction={"row"}>
              <Grid container justifyContent="space-between">
                <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleLeft} onClick={handleAddConfigDialogOpen}>
                  <AddIcon />
                </ArgonButton>
                <ArgonButton
                  iconOnly
                  variant="contained"
                  color={isFavorite ? "primary" : "secondary"}
                  size="large"
                  sx={buttonStyleLeft}
                  onClick={toggleFavoriteForCurrentType}>
                  {isFavorite ? <Star /> : <StarBorder />}
                </ArgonButton>
              </Grid>
              <Grid item xs={12} lg={12}>
                <DataTable
                  entriesPerPage={{
                    defaultValue: 100,
                    entries: []
                  }}
                  enableSelection={false}
                  table={{
                    columns: defaultColumns.concat(Array.from(types[selectedType].active_module).map(type => {
                      if (type !== undefined) {
                        for (let module_active of type) {
                          for (let module of modulesTAF) {
                            if (module_active.name === module.name) {
                              return { Header: module_active.name, accessor: module_active.name.toLowerCase().replaceAll(" ", "_") }
                            }
                          }
                        }
                        return null;
                      } else {
                        return null;
                      }
                    })).filter(item => item !== null),
                    rows: Array.from(currentConfigs).map(config => {

                      let resultsFilter = {
                        name: config.name,
                        notes: config.notes ?? "",
                      }

                      if (config.enabled) {

                        resultsFilter["status"] = window.user.canToggleType() ? <Tooltip key={"tooltip-" + config.name} title="Click to Disable" placement="top-start">
                          <ArgonButton key={"status-" + config.name} color="success"
                            onClick={() => handleDisableValue(config.name)}>Enabled</ArgonButton>
                        </Tooltip> : <></>;

                        for (let filter of config.filters) {
                          resultsFilter[filter.module.name.toLowerCase().replaceAll(" ", "_")] = window.user.canToggleModuleForType() ? <Switch key={filter.module.name.toLowerCase().replaceAll(" ", "_")} checked={filter.enabled}
                            onClick={(event) => handleChangeValueModule(event, filter.module.name, filter.module._id, config.name)} /> : <></>;
                        }

                        return resultsFilter;

                      } else {

                        resultsFilter["status"] = window.user.canToggleType() ? <Tooltip key={"tooltip-" + config.name} title="Click to Enable" placement="top-start">
                          <ArgonButton key={"status-" + config.name} color="warning"
                            onClick={() => handleEnableValue(config.name)}>Disabled</ArgonButton>
                        </Tooltip> : <></>;

                        for (let filter of config.filters) {
                          resultsFilter[filter.module.name.toLowerCase().replaceAll(" ", "_")] = window.user.canToggleModuleForType() ? <Switch key={filter.module.name.toLowerCase().replaceAll(" ", "_")} checked={filter.enabled}
                            onClick={(event) => handleChangeValueModule(event, filter.module.name, filter.module._id, config.name)} disabled /> : <></>;
                        }

                        return resultsFilter;
                      }
                    }),
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }

  const getCardHeader = () => {
    return Object.keys(types).map(t => {
      let name = t;
      let icon = "";
      if (name === "Market") {
        icon = <ShoppingCart />
      } else if (name === "Project") {
        icon = <Public />
      } else if (name === "Capability") {
        icon = <ImportantDevices />
      } else if (name === "Test Type") {
        icon = <Description />
      } else if (name === "Build") {
        icon = <Apps />
      } else if (name === "Environment") {
        icon = <Layers />
      } else if (name === "Platform") {
        icon = <Devices />
      }

      return <Tab label={name} key={name} icon={icon} />
    });
  }

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={11} md={0} p={1}>
            <Card>
              <Box>
                {!(types == null || configs == null) ?
                  <Tabs
                    value={selectedTypeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                  >
                    {[...getCardHeader()]}
                  </Tabs>
                  :
                  <Card>
                  </Card>}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={1} md={0}>
            {window.user.canCreateConfigType() && <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleRight} onClick={handleAddTypeDialogOpen}>
              <AddIcon />
            </ArgonButton>}
            {window.user.canEditConfigType() && <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleRight} onClick={handleEditTypeDialogOpen}>
              <EditIcon />
            </ArgonButton>}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pt={5}>
          <ArgonBox height="40px" />
          {getTable()}
        </Grid>
      </ArgonBox>
      <Dialog
        open={addTypeDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Add Configuration Type"}</DialogTitle>
        <DialogContent>
          <AddNewConfigurationTypeView closeDialog={handleClose} modulesActive={modulesTAF} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={editTypeDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Edit Configuration: " + selectedType}</DialogTitle>
        <DialogContent>
          <EditConfigurationTypeView closeDialog={handleClose} modulesActive={modulesTAF} types={types} selectedType={selectedType} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={addConfigDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Add Configuration"}</DialogTitle>
        <DialogContent>
          <AddNewConfigurationView closeDialog={handleClose} configType={selectedType} />
        </DialogContent>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}


export default TestConfiguration;

import apiClient from "api/BackendApi";

const Settings = {
  getSettingsAll: () => apiClient.get("/be/settings/all"),
  getSetting: (area, setting) => apiClient.post("/be/settings/get", { area: area, setting: setting }),
  updateSetting: (setting_id, enabled) => apiClient.post("/be/settings/update", { setting_id: setting_id, enabled: enabled }),
  updateSettingSelected: (setting_id, selected) => apiClient.post("/be/settings/updateSelected", { setting_id: setting_id, selected: selected })
}

export {
  Settings
};

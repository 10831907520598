/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

import ReactEcharts from "echarts-for-react";

function ProductivityChart({ name, points, comparingBuild, selectedBuild }) {
    const tag = useRef();

    const { white, info, monitoring_primary, monitoring_secondary, dark } = colors;
    const { size } = typography;
    const chartRef = useRef(null);
    const [options, setOptions] = useState(null);

    const [details, setDetails] = useState(null)

    useEffect(() => {
        let selectedPoints = points?.selected?.reverse() ?? []
        let comparingPoints = points?.comparing?.reverse() ?? []

        let threshold = points?.threshold ?? null

        if (selectedPoints.length != comparingPoints.length) {
            while (selectedPoints.length < comparingPoints.length) {
                selectedPoints.push(null)
            }
            while (selectedPoints.length > comparingPoints.length) {
                comparingPoints.push(null)
            }
        }

        let series = [{
            name: comparingBuild,
            data: comparingPoints.map(v => v?.toFixed(2)),
            showSymbol: true,
            type: 'line',
            color: monitoring_primary.main,
            symbolSize: 2,
            lineStyle: {
                color: monitoring_primary.main
            },
            areaStyle: {
                color: monitoring_primary.main,
                opacity: 0.5
            },
            smooth: true,
            markLine: (threshold != null && threshold > 0) ? {
                silent: true, // Disable interaction
                symbol: 'none', // No arrow symbols
                lineStyle: {
                    color: 'grey', // Red dashed line
                    type: 'dashed',
                    width: 1.2
                },
                label: {
                    show: true,
                    position: 'insideEndTop', // 'start', 'middle', or 'end'
                    distance: [5, 5],
                    formatter: threshold + "ms", // Explicit label
                    color: 'grey', // Match line color
                },
                data: [
                    {
                        yAxis: threshold, // Fixed at y=100
                    }
                ]
            } : {}
        }]

        if (selectedBuild != "Build undefined") {
            series.push({
                name: selectedBuild,
                data: selectedPoints.map(v => v?.toFixed(2)),
                showSymbol: true,
                type: 'line',
                color: monitoring_secondary.main,
                symbolSize: 2,
                lineStyle: {
                    color: monitoring_secondary.main
                },
                areaStyle: {
                    color: monitoring_secondary.main,
                    opacity: 0.5
                },
                smooth: true
            })
        }

        setOptions({
            legend: {
                top: 'top',
                left: 'center',
                orient: 'horizontal',
                data: selectedBuild == "Build undefined" ? [comparingBuild] : [comparingBuild, selectedBuild]
            },
            xAxis: {
                data: comparingPoints.map((v, idx) => "Run " + (idx + 1)),

                type: "category"
            },
            yAxis: {
                show: false
            },
            grid: [
                {
                    show: false,
                    left: 0,
                    top: '10%',
                    bottom: 0,
                    right: 0
                }
            ],
            tooltip: {
                show: true,
                trigger: 'item'
            },
            series: series
        });
    }, [points])

    useEffect(() => {
        setDetails(getDetails())
    }, [points])

    const getDetails = () => {
        if (points == null) return null;

        if ((points.selected?.length ?? 0) == 0) return null
        if (points.selected?.every(element => element === null) ?? true) return null

        let comparingTotal = points.comparing.reduce((a, b) => a + b, 0)
        let selectedTotal = points.selected.reduce((a, b) => a + b, 0)

        let comparingAverage = comparingTotal / points.comparing.length
        let selectedAverage = selectedTotal / points.selected.length

        let diff = comparingAverage - selectedAverage
        let absDiff = Math.abs(diff).toFixed(2)

        let keyword = ""
        let icon = "trending_flat"
        let iconColor = "primary"

        if (diff == 0) {
            return (
                <ArgonBox display="flex" alignItems="center">
                    <ArgonBox fontSize={size.lg} color={iconColor} mb={0.3} mr={0.5} lineHeight={0}>
                        <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
                    </ArgonBox>
                    <ArgonTypography variant="button" color="dark" fontWeight="medium">
                        <ArgonTypography variant="button" color="dark" fontWeight="regular">
                            Same as
                        </ArgonTypography>
                        &nbsp;{selectedBuild}
                    </ArgonTypography>
                </ArgonBox>
            );
        }

        if (isNaN(diff)) {
            return null
        }

        if (diff > 0) {
            keyword = " more "
            icon = "trending_down"
            iconColor = "error"
        } else {
            keyword = " less "
            icon = "trending_up"
            iconColor = "success"
        }

        return (
            <ArgonBox display="flex" alignItems="center">
                <ArgonBox fontSize={size.lg} color={iconColor} mb={0.3} mr={0.5} lineHeight={0}>
                    <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
                </ArgonBox>
                <ArgonTypography variant="button" color="dark" fontWeight="medium">
                    {absDiff} ms
                    <ArgonTypography variant="button" color="dark" fontWeight="regular">
                        {keyword} than
                    </ArgonTypography>
                    &nbsp;{selectedBuild}
                </ArgonTypography>
            </ArgonBox>
        );
    }

    return (
        <Card>
            <ArgonBox p={2}>
                <ArgonBox display="flex" justifyContent="space-between">
                    <ArgonBox>
                        <ArgonTypography variant="h6" fontWeight="medium" color="dark">
                            {name}
                        </ArgonTypography>
                        {details != null && details}
                    </ArgonBox>
                </ArgonBox>
            </ArgonBox>
            {options != null &&
                <div ref={chartRef}>
                    <ReactEcharts option={options} />
                </div>}
        </Card>
    );
}

export default ProductivityChart;

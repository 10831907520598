import apiClient from "api/BackendApi";


const TestConfig = {
  getTypes:  () => apiClient.get("/be/test-configuration/types"),
  addType: (name, module) => apiClient.post("/be/test-configuration/types", {"name": name, "module": module}),
  editType: (name, module) => apiClient.post("/be/test-configuration/editTypes", {"name": name, "module": module}),
  toggleTypeFavorite: (name) => apiClient.post("/be/test-configuration/toggleTypeFavorite", {"name": name}),
  getConfigs: () => apiClient.get("/be/test-configuration/configurations"),
  addConfig: (name, notes, configType) => apiClient.post("/be/test-configuration/configurations", {"name": name, "notes": notes, "type": configType, "enabled": true}),
  editConfig: (name, configType, isEnabled) => apiClient.post("/be/test-configuration/editConfigurations", {"name": name, "type": configType, "enabled": isEnabled}),
  editConfigModule: (name, configType, module, isEnabled) => apiClient.post("/be/test-configuration/editConfigurationsModule", {"name": name, "type": configType, "module": module, "enabled": isEnabled}),
  getConfigsByName: (nameType) => apiClient.post("/be/test-configuration/configurations/config", {"nameType": nameType}),

}

export {
  TestConfig
};
